import React from 'react';
import { Row, Col } from 'reactstrap';
import { useWindowSize } from '@react-hook/window-size';
import application_contants from '../../constants/application_contant';
import Strip from '../../customComponent/Strip/Strip';
import LocationInformation from '../../Utils/Json/locationInformation';
import SocialInfo from '../../Utils/Json/socialInfo';
import UsaLogo from '../../assets/usa_logo.png'
const Footer = () => {
    const [width] = useWindowSize();
    return (
        <Strip
            id="tst-footer"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row className="mx-0 footer-bg">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="py-5">
                    <Row>
                        <Col xs={10} sm={10} md={12} lg={12} xl={12} className="mx-auto" >
                            <Row className="mx-0  social-info--mobile-position ms-md-3">
                                <Col xs={12} sm={12} md={12} lg={8} xl={9} className="d-flex align-items-center social-icon--position justify-content-md-center">
                                    {SocialInfo.map((item, index) => (
                                        <div key={index}>
                                            <a href={item.link} target="_blank" rel="noreferrer">
                                                <img src={item.icon} alt="" className="social-icon-width" />
                                            </a>
                                        </div>
                                    ))}

                                </Col>
                                {width >= application_contants.MOBILE_BREAKPOINT ? null :
                                    <Col xs={12} sm={12} md={12} lg={4} xl={3} className="pb-3 text-center">
                                        <h4 className="h4-small external-section--title lato-Regular robotoSlab-Bold pb-2 text-md-center">Find Us</h4>
                                    </Col>}
                            </Row>
                            <Row>
                                <Col xs={12} className="text-md-center pb-4 mt-3">
                                    <a href="/usa"><div className='usa_logo_img'>
                                        <img src={UsaLogo} alt="usa_logo" />
                                    </div></a>
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col xs={2} sm={2} md={2} lg={9}>

                        </Col> */}
                        <Col xs={12} sm={12} md={12} lg={7} xl={7} className="ps-md-5">
                            <Row className="social-address--mobile-position">
                                {LocationInformation.map((item, index) => (
                                    <Col xs={10} sm={10} md={3} lg={3} xl={3} key={index}>
                                        <Row className="mx-0">
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <div className="d-flex  pb-3">
                                                    <div>
                                                        <h6 className="h6-small external-section--title lato-Regular robotoSlab-Bold pb-2">{item.name}</h6>
                                                        <p className="external-section--title  line lato-Regular text-start">{item.address}</p>
                                                        {item.address2 && <p className="external-section--title lato-Regular line text-start mt-3">{item.address2}</p>}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                ))}
                            </Row>

                        </Col>
                        <Col xs={12} sm={12} md={12} lg={5} xl={5} className="pe-md-3">
                            <Row className="mx-0 mpt-16px social-address--mobile-position">
                                <Col xs={10} sm={10} md={12} lg={12} xl={12}>
                                    <div className="d-flex pb-3">
                                        <div>
                                            <h6 className="h6-small external-section--title lato-Regular robotoSlab-Bold pb-2">More Information:-</h6>
                                            <p className="external-section--title lato-Regular text-nowrap">
                                                Permanent Account Number : AAATG5061F
                                                <br />
                                                12A Provisional Reg. No.: AAATG5061FE20214 <br />
                                                80G Provisional Reg. No.: AAATG5061FF20214 <br />
                                                FCRA Reg. No.: 231660874 valid till 30th June 2027<br />
                                                DARPAN Id: DL/2017/0166410 <br />
                                                CSR Reg. No.: CSR00000592
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Col>
            </Row>
            <Row className="mx-0 copy-bg py-3">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <h6 className="h6-small external-section--title lato-Regular align-center">Copyright &copy; Going to School, India, 2025</h6></Col>
            </Row>
        </Strip>
    )
};


export default Footer;